// Font weights
$light: 400;
$regular: 500;
$bold: 700;
$ultra_bold: 900;

// Base Font
$base-font-family: 'Open Sans', sans-serif;
$base-font-weight: $regular;
$base-font-size: 16px;
$font-title:'Dosis', sans-serif;

//base colors
$color-primary: #000000;
$color-secondary: #e30613;
$color-alt: #e30613;
$color-white: #fbf8f8;

//link colors
$color-link: $color-secondary;
$color-link-dark: darken($color-secondary, 10);
$color-link-light: $color-white;

//text colors
$color-text: $color-primary;
$color-text-light: lighten($color-primary, 10);
$color-text-lightest: $color-white;

//footer colors
$footer-primary: #333033;
$footer-secondary: #232323;
$footer-color-link: #686969;
$footer-color-tekst: #686969;